import React from "react";
import { Link } from "gatsby";
import { FiPhone, FiSmartphone, FiMail } from "react-icons/fi";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Landscape gardening services in West Kent."
      description="Transforming outdoor spaces in Tonbridge, Sevenoaks, Hildenborough"
    />

    {/* Welcome section */}

    <section class="hero">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">
            <Link to="/">
              <img
                src={require("../images/logo-tectonic-landscapes-large.svg")}
                alt="Tectonic landscapes"
                className="tectonic-logo"
              />
            </Link>
          </h1>
          <h2 class="subtitle">
            Tectonic Landscapes - specialists in garden design and landscaping,
            based in West Kent. Website coming soon.
          </h2>

          <p className="has-text-centered is-v-padded">
            <FiPhone className="icon icon-position" size="2rem" />
            01732 500507
          </p>
          <p className="has-text-centered is-v-padded">
            <FiSmartphone className="icon icon-position" size="2rem" />
            07512 330350
          </p>
          <p className="has-text-centered is-v-padded">
            <FiMail className="icon icon-position" size="2rem" />{" "}
            info@tectonicltd.co.uk
          </p>
        </div>
      </div>
    </section>

    {/* Highlights section */}
    <div className="container is-widescreen">
      <section className="level">
        <div className="level-item has-text-centered">
          <div>
            <img
              src={require("../images/icons/icon-tapemeasure.svg")}
              alt="tapemeasure"
            />
            <p className="title is-4">Garden transformations</p>
            <p className="heading">Turn key solutions</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <img
              src={require("../images/icons/icon-digger.svg")}
              alt="measuring gauage"
            />
            <p className="title is-4">Outdoor entertaining</p>
            <p className="heading">Timber, or composite structures</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <img
              src={require("../images/icons/icon-barrow.svg")}
              alt="barrow"
            />
            <p className="title is-4">Driveways &amp; parking</p>
            <p className="heading">From Block paving to porcelain tiles</p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
